.navbar {
	position: fixed;
	top: 0;
	width: 100%;
	transition: background-color 0.5s;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.navbar.dark-bg {
	background-color: rgba(22, 24, 40, 0.8);
	transition: background-color 0.5s;
}

.navbar .inner-container {
	height: 4rem;
	margin: 0 auto;
	/* max-width: 665px; */
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 2rem;
}

.navbar .inner-container .sign-in-menu {
	font-family: 'Poppins', sans-serif;
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-basis: 33%;
	justify-content: flex-end;
}
.navbar .inner-container .sign-in-menu .sign-up {
	cursor: pointer;
	margin: 0 2rem;
}

.navbar .inner-container .sign-in-menu .sign-up a {
	text-decoration: none;
	color: white;
}

.navbar .inner-container .sign-in-menu .sign-in {
	cursor: pointer;
	background-color: rgba(65, 65, 92, 0.9);
	padding: 0.5rem 2rem;
	border-radius: 5px;
}
.navbar .inner-container .img-container {
	flex-basis: 33%;
}
.navbar .inner-container .img-container img {
	height: 1.6rem;
	cursor: pointer;
}

.navbar .name {
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	font-size: 1.4rem;
	text-align: center;
	flex-basis: 33%;
	opacity: 1;
	transition: opacity 0.5s linear;
	cursor: pointer;
}

.navbar .name.scrolled {
	/* visibility: hidden; */
	opacity: 0;
	transition: opacity 0.5s linear;
}

@media screen and (max-width: 450px) {
	.navbar .name {
		display: none;
	}
}
